import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDragon, faCat, faCrow, faDog, faDove, faFrog, faKiwiBird } from '@fortawesome/free-solid-svg-icons'

library.add (faDragon, faCat, faCrow, faDog, faDove, faFrog, faKiwiBird)

class SiteFooter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            footerIcons: ['dragon', 'cat', 'crow', 'dog', 'dove', 'frog', 'kiwi-bird'],
            footerIconIndex: null,
            bounce: false
        }
    }

    randomizeFooterIcon() {
        this.setState({ footerIconIndex: Math.floor(Math.random() * this.state.footerIcons.length)})
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('animal');
        if (this.isBottom(wrappedElement)) {
            this.setState({ bounce: true})
        } else {
            this.setState({ bounce: false })
        }
    };

    componentDidMount() {
        this.randomizeFooterIcon();
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    render() {
        return (
            <div className="footer">
              <FontAwesomeIcon 
                icon={this.state.footerIcons[this.state.footerIconIndex]} 
                id="animal"
                className={this.state.bounce ? 'animated bounce' : ''}
                onClick={() => this.setState({ bounce: true })}
                onAnimationEnd={() => this.setState({ bounce: false })}
              />
              {/*<i
                    id="animal"
                    className={`fas fa-${this.state.footerIcons[this.state.footerIconIndex]} ${ this.state.bounce ? 'animated bounce' : ''}`}
                    onClick={() => this.setState({ bounce: true })}
                    onAnimationEnd={() => this.setState({ bounce: false })}
                />*/}
                <div className="footer-company-label">ETD Interactive © 2020, an erict.dev company</div>
            </div>
        )
    }
}

SiteFooter.propTypes = {
}

export default SiteFooter;
