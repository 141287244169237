import React from 'react'
import { Link } from 'gatsby'

import "../styles/cta.css"

function Cta(props) {
    const { title, buttonText, link, maxWidth, bg} = props;
    return (
        <div className="cta-container">
          <div className="cta-content">
            <div className="cta-title" style={{ maxWidth }}>{title}</div>
            <Link to={link}>
                <div className="cta-button">{buttonText}</div>
            </Link>
          </div>
          <div className="cta-image" style={{ backgroundImage: `url(${bg})`}} />
        </div>
    )
}

export default Cta;
