import React from "react"

import SiteNav from './SiteNav';
import SiteFooter from './SiteFooter';

import 'animate.css';
import 'bootstrap/dist/css/bootstrap.css';

import "../styles/layout.css"

const Layout = ({ navBackgroundColor, animated, children, noNav }) => {
  return (
    <div className="site-container">
      { noNav ? <></> : <SiteNav backgroundColor={navBackgroundColor} animated={animated} /> }
      { children }
      <SiteFooter />
    </div>
  )
}

export default Layout

