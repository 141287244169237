import React from 'react'
import { Link } from "gatsby"

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import "../styles/nav.css"

function SiteNav(props) {
    const { backgroundColor, animated } = props
    return (
        <Navbar className="navbar-spacer" collapseOnSelect expand="lg" style={{ backgroundColor }}>
          <Navbar.Brand><Link to="/" className="brand-text">ETD Interactive</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav.Link href="/about"><div className={`nav-text ${animated ? 'animated fadeInUp' : '' }`} id="about">about</div></Nav.Link>
                    <Nav.Link href="/portfolio"><div className={`nav-text ${animated ? 'animated fadeInUp delay-1' : '' }`} id="portfolio">portfolio</div></Nav.Link>
                    <Nav.Link href="/services"><div className={`nav-text ${animated ? 'animated fadeInUp delay-2' : '' }`} id="services">services</div></Nav.Link>
                    <Nav.Link href="/contact"><div className={`nav-text ${animated ? 'animated fadeInUp delay-3' : '' }`} id="contact">contact</div></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default SiteNav;
