import React from 'react'
import Layout from "../../../components/Layout";
import CaseStudyHeader from "../../../components/CaseStudyHeader";
import Cta from "../../../components/Cta";

import body from './index.md'

import SDLC from "../../../images/SDLC.png"

const ReactMarkdown = require('react-markdown')

function SDLCCaseStudy() {
    return (
        <Layout animated={true} >

          <div className="case-study-page-header-container">
            <div className="case-study-page-header-title">Case Study</div>
          </div>

            <CaseStudyHeader 
                reverse={false}
                type={'tablet'}
                color={'#fafafa'}
                textColor={'dark'}
                title={'SDLC'}
                image={SDLC}
                tags={['Maps & Geolocation', 'Admin Dashboard', 'Web app']}
            />

            <div className="case-studies-content-container">
              <ReactMarkdown source={body} />
            </div>

            <Cta title="see more of our work" buttonText="portfolio" link="/portfolio"/>
        </Layout>

    
    )
}

export default SDLCCaseStudy;
