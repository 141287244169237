import React from 'react'
import Fade from 'react-reveal/Fade';

function CaseStudyHeader(props) {
    const { reverse, type, title, color, textColor, image, tags, laptop, link} = props;
    return (
        <div className={`${reverse ? 'showcase-container-reverse' : 'showcase-container'}`} style={{ backgroundColor: color, color: `${textColor === 'light' ? '#FAFAFA' : '#535353'}`}}>
            <Fade bottom>
                <div id={type} className={`showcase-image ${ laptop ? 'laptop' : '' }`} style={{ backgroundImage: `url('${image}')`}} />
            </Fade>
            <Fade cascade>
                <div className="showcase-content">
                    <div className="showcase-text">
                      { link 
                          ? <a href={link} target="_blank"><div className="case-study-title">{title}</div></a>
                          : <div className="case-study-title">{title}</div>
                      }
                        <div className="case-study-tag-container">
                            { tags.map(tag => <div className="case-study-tag" style={{ borderColor: `${textColor === 'light' ? '#FAFAFA' : '#535353' }`}}>{tag}</div>) }
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default CaseStudyHeader;
